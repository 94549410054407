import { RouteSectionProps } from "@solidjs/router";
import { Show } from "solid-js";
import { Sidebar } from "~/components/pluto/sidebar";
import { PlutoProvider } from "~/context/pluto";

export default function (props: RouteSectionProps) {
  const showSidebar = () =>
    props.location.pathname.includes("chat") ||
    props.location.pathname.includes("new") ||
    props.location.pathname.includes("profile");

  return (
    <PlutoProvider>
      <div class="flex h-screen w-full overflow-hidden bg-black bg-cover bg-no-repeat">
        <Show when={showSidebar()}>
          <Sidebar />
        </Show>
        {props.children}
      </div>
    </PlutoProvider>
  );
}
