import { A, redirect, useLocation, useNavigate } from "@solidjs/router";
import {
  createEffect,
  createMemo,
  createSignal,
  For,
  Match,
  onMount,
  Show,
  Switch,
} from "solid-js";
import {
  giftIcon,
  novaLinkGradient,
  novaStar,
  userCircle,
} from "~/assets/assets";
import { usePluto } from "~/context/pluto";
import { getAllConversations, logout } from "~/server/apis/client_apis";
import { PhosphorIcon } from "~/widgets/icons";
import { Appbar } from "./app_bar";

export const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = createSignal(false);
  const { plutoGifts } = usePluto();

  const toggleSidebar = () => {
    setIsOpen(!isOpen());
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  const isProfilePage = () => location.pathname.includes("profile");

  const logoutHandler = async () => {
    await logout();
    navigate("/logout");
  };

  const [latestConversations, setLatestConversations] = createSignal(
    plutoGifts?.conversations
  );

  onMount(async () => {
    const conversations = await getAllConversations();
    setLatestConversations(conversations);
  });

  const conversationsDetails = createMemo(() => {
    return latestConversations() ?? plutoGifts?.conversations;
  });

  return (
    <>
      <Switch>
        <Match when={isProfilePage()}>
          <div class="fixed left-0 top-0 z-20 flex w-full items-center justify-end p-4 text-white md:hidden">
            <button
              onClick={toggleSidebar}
              class={`left-4 top-4 z-50 md:hidden ${isOpen() ? "hidden" : "fixed"} `}
            >
              <PhosphorIcon
                name={"list"}
                size="bold"
                fontSize={24}
                class="text-white"
              />
            </button>

            <button
              class="cursor-pointer text-subtitleSmall text-errorDark underline"
              onClick={logoutHandler}
            >
              Logout
            </button>
          </div>
        </Match>
        <Match when={!isProfilePage()}>
          <div class="fixed left-0 top-0 z-20 flex w-full items-center justify-center bg-black p-4 text-white md:hidden">
            <button
              onClick={toggleSidebar}
              class={`left-4 top-4 z-50 md:hidden ${isOpen() ? "hidden" : "fixed"} `}
            >
              <PhosphorIcon
                name={"list"}
                size="bold"
                fontSize={24}
                class="text-white"
              />
            </button>

            <div class="flex items-center gap-2">
              <img src={novaStar} alt="Star Logo" class="h-8 w-8" />
              <span class="text-lg font-bold">PLUTO</span>
              <span class="rounded-full border border-white px-2 py-0.5 text-[10px] font-semibold">
                BETA
              </span>
            </div>
          </div>
        </Match>
      </Switch>

      <div
        class={`fixed inset-y-0 left-0 z-40 w-[280px] transform bg-[#030203] transition-transform duration-300 ease-in-out ${
          isOpen() ? "translate-x-0" : "-translate-x-full"
        } md:relative md:translate-x-0`}
      >
        <div class="h-full w-full border-r border-[#232323] p-3">
          <div class="flex h-12 items-center justify-between">
            <Appbar />
            <button onClick={toggleSidebar} class="md:hidden">
              <PhosphorIcon
                name={"x"}
                size="bold"
                fontSize={18}
                class="text-white"
              />
            </button>
          </div>
          <div class="border-b border-basePrimaryDark pb-4 pt-3">
            <A
              href="/gifts/new"
              onClick={closeSidebar}
              class="flex h-10 w-full items-center justify-center gap-1 rounded-[41px] bg-baseTertiaryMedium px-3"
            >
              <img src={giftIcon} alt="gift icon" />
              <p class="pt-1 text-buttonMedium font-bold text-textDark">
                {" "}
                Create new gift
              </p>
            </A>
          </div>
          <nav>
            <p class="px-3 py-4 text-smallBold uppercase tracking-[1.1px] text-textNormal">
              My gifts
            </p>
            <ul class="min-h-[620px] overflow-y-auto">
              <For each={conversationsDetails()}>
                {(chat, index) => {
                  return (
                    <A
                      href={`/gifts/chat/${chat.id}`}
                      onClick={closeSidebar}
                      class={`relative flex rounded-lg px-5 py-2.5 text-mediumBold hover:text-white`}
                      activeClass="bg-[#281D2D] text-white"
                      inactiveClass="text-[#96869E]"
                    >
                      <Show
                        when={
                          chat.id === location.pathname.split("/gifts/chat/")[1]
                        }
                      >
                        <img
                          src={novaLinkGradient}
                          class="absolute left-0 top-0 h-full"
                          alt=""
                        />
                      </Show>
                      {chat.status === "COMPLETED"
                        ? chat.title
                        : `Gift ${index() + 1}`}
                    </A>
                  );
                }}
              </For>
            </ul>
            <div class="sticky bottom-0 bg-[#030203] px-2 pb-6 pt-4">
              <A
                href="/gifts/profile"
                onClick={closeSidebar}
                class="flex items-center gap-1"
              >
                <img src={userCircle} alt="profile" class="h-5 w-5" />
                <Show when={plutoGifts?.userName}>
                  <p class="text-mediumBold text-white">
                    {plutoGifts?.userName}
                  </p>
                </Show>
              </A>
            </div>
          </nav>
        </div>
      </div>

      {isOpen() && (
        <div
          class="fixed inset-0 z-30 bg-black bg-opacity-50 md:hidden"
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
};
